import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ContourViewer } from "./Pages/ContourViewer/ContourViewer";
import { Layout } from "./Pages/Layout/Layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route index element={<ContourViewer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;