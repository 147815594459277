import axios from "axios";

export const getSeaBoundariesByGeometry = async (coordinates) => {
  return axios
    .post(process.env.REACT_APP_SERVER_URL + "/SeaBoundary/GetByGeometry", {
        LongCoordinates: coordinates.map((coordinate) => {
          return coordinate[0];
        }),
        LatCoordinates: coordinates.map((coordinate) => {
          return coordinate[1];
        }),
    });
};