import { Overlay } from "ol";
import { useContext, useEffect, useRef, useState } from "react";
import MapContext from "../Map/MapContext";

const ContourHeightOverlay = ({feature, clickPos}) => {
  const [overlay, setOverlay] = useState(null);
  const overlayRef = useRef();
  const { map } = useContext(MapContext);

  useEffect(() => {
    const ov = new Overlay({
      element: overlayRef.current,
      positioning: "top-center",
      stopEvent: true,
      offset: [0, +10],
    });

    setOverlay(ov);
  }, []);
 
  useEffect(() => {
    console.log("Feature has changed", overlay, feature);
    if (!overlay || !feature) return;
    console.log("Feature pos", feature.getGeometry().getCoordinates());
    overlay.setPosition(clickPos);
  }, [overlay, feature]);

  useEffect(() => {
    if (!map || !overlay) return;
    setTimeout(() => {
      map.addOverlay(overlay);
    }, 1000);  // 1 second delay

    return () => { 
      map.removeOverlay(overlay);
    };
  }, [overlay, map]);

  return (
    <p
      className="overlay-box"
      ref={overlayRef}
      style={{ display: !feature ? "none" : "block" }}
    >
      {feature && (
        <>
          <p>{feature.get("elevation")}m</p>
        </>
      )}
    </p>
  );
};
export default ContourHeightOverlay;
