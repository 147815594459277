import * as ol from "ol";
import { defaults as defaultControls } from 'ol/control';
import { defaults as defaultInteractions, DragRotate, PinchRotate } from 'ol/interaction';
import React, { useEffect, useRef, useState } from "react";
import "./Map.css";
import MapContext from "./MapContext";


const Map = ({ children, view, onPointerMove, onClick }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);


  useEffect(() => {
    // Configure map options
    let options = {
      view: view,
      layers: [],
      controls: defaultControls({attribution: false, rotate: false, zoom:false}),
      interactions: defaultInteractions().getArray().filter(function(interaction) {
        return !(interaction instanceof DragRotate || interaction instanceof PinchRotate);
      }),
      overlays: []
    };

    // Initialize the map with the above options
    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);

    // Save the map object
    setMap(mapObject);

    // Cleanup on unmount
    return () => mapObject.setTarget(undefined);
  }, []);


  useEffect(() => {
    if (!map || !view) return;
    map.setView(view);
  }, [view]);

  useEffect(() => {
    if (!map) return;
    map.on("pointermove", onPointerMove);
  }, [onPointerMove]);

  useEffect(() => {
    if (!map) return;
    map.on("click", onClick);
  }, [onClick]);

  return (
    <MapContext.Provider value={{ map }}>
      {" "}
      <div ref={mapRef} className="ol-map">
        {" "}
        {children}{" "}
      </div>{" "}
    </MapContext.Provider>
  );
};

export default Map;
