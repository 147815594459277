import { Container, Navbar } from "react-bootstrap";
import "./ContourViewerNavbar.css";

function ContourViewerNavbar() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary navbar">
      <Container>
        <Navbar.Brand href="/"><img src="/contour32.png" style={{marginRight:10}} />Contour Viewer</Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default ContourViewerNavbar;
