import OLVectorLayer from "ol/layer/Vector";
import { useContext, useEffect, useState } from "react";
import MapContext from "../Map/MapContext";
import VectorLayerContext from "./VectorLayerContext";
const VectorLayer = ({ style, zIndex = 0, children }) => {
  const [vectorLayer, setVectorLayer] = useState(null);
  const { map } = useContext(MapContext);

  useEffect(() => {
    const vectorLayer = new OLVectorLayer({ style });
    setVectorLayer(vectorLayer);
  }, []);

  useEffect(() => {
    if (!vectorLayer) return;
    vectorLayer.style = style;
  }, [style]);

  useEffect(() => {
    if (!map || !vectorLayer) return;

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, vectorLayer]);

  return (
    <VectorLayerContext.Provider value={{ vectorLayer }}>
      <div>{children}</div>
    </VectorLayerContext.Provider>
  );
};
export default VectorLayer;
