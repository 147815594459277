import VectorSource from "ol/source/Vector";
import { useContext, useEffect, useState } from "react";
import VectorLayerContext from "../Layers/VectorLayerContext";

const MyVectorSource = ({ features, display = true }) => {
  const [vectorSource, setVectorSource] = useState(null);
  const { vectorLayer } = useContext(VectorLayerContext);

  useEffect(() => {
    // Initialize the vector source
    const v = new VectorSource({
      features: features,
    });
    setVectorSource(v);
  }, []);

  useEffect(() => {
    if (!vectorLayer) return;
    vectorLayer.setSource(vectorSource);
  }, [vectorLayer, vectorSource]);

  useEffect(() => {

    if (!vectorSource) return;
    vectorSource.clear();
    if(!display) return;

    vectorSource.addFeatures(features);
    vectorSource.changed();
    setVectorSource(vectorSource);
  }, [features, display, vectorSource]);

  return null;
};

export default MyVectorSource;
