import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";

export const GetContourStyle =() =>{
const elevationStyleCache = {};
    return (feature) => {
      const elevation = feature.get("elevation");
      const maxElevation = feature.get("maxElevation");
      const minElevation = feature.get("minElevation");

      // const drawElevation = [];
      // for (var i = maxElevation; i > 0; i = i - 40) {
      //   drawElevation.push(i);
      // }

      // if (!drawElevation.includes(elevation)) {
      //   return null;
      // }

      let style = elevationStyleCache[elevation];

      if (style != null) {
        return style;
      }

      if (style == null) {
        style = new Style({
          stroke: new Stroke({
            color: GetElevationColour(elevation, maxElevation, minElevation),
            width: 1,
            lineDash: [8, 4],
          }),
        });

        elevationStyleCache[elevation] = style;
      }
      return style;
    };
};

 const GetElevationColour = (elevation, maxElevation, minElevation) => {
    if (elevation <= minElevation) {
      return "rgba(255, 255, 255, 1)"; // White for minimum elevation
    } else if (elevation >= maxElevation) {
      console.log("Over max elevation", maxElevation, elevation);
      return "rgba(255, 0, 0, 1)"; // Red for maximum elevation
    }

    // Calculate the proportion of the elevation to its range
    const ratio = (elevation - minElevation) / (maxElevation);

    // Green and blue components decrease as the elevation increases.
    let green = Math.round(220 * (1 - ratio));
    let blue = Math.round(220 * (1 - ratio));

    //  green = green - 50;
    //   blue = blue - 100;

    return `rgba(220, ${green}, ${blue}, 1)`;
  };