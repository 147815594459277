import Feature from "ol/Feature";
import olView from "ol/View";
import LineString from "ol/geom/LineString";
import { fromLonLat, toLonLat } from "ol/proj";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { useEffect, useMemo, useState } from "react";
import { FormCheck } from "react-bootstrap";
import { getByGeometry } from "../../Api/ContourApi";
import { getSeaBoundariesByGeometry } from "../../Api/SeaBoundaryApi";
import { LoadingSpinner } from "../../Components/LoadingSpinner/LoadingSpinner";
import { DrawInteraction } from "../../MapComponents/Interactions/DrawInteration";
import Interactions from "../../MapComponents/Interactions/Interactions";
import Layers from "../../MapComponents/Layers/Layers";
import TileLayer from "../../MapComponents/Layers/TileLayer";
import VectorLayer from "../../MapComponents/Layers/VectorLayer";
import Map from "../../MapComponents/Map/Map";
import ContourHeightOverlay from "../../MapComponents/Overlays/ContourHeightOverlay";
import Overlays from "../../MapComponents/Overlays/Overlays";
import MyVectorSource from "../../MapComponents/Source/VectorSource";
import osm from "../../MapComponents/Source/osm";
import { GetContourStyle } from "./ContourStyle";
import "./ContourViewer.css";

export const ContourViewer = () => {
  const [contourFeatures, setContourFeatures] = useState([]);
  const [seaBoundaryFeatures, setSeaBoundaryFeatures] = useState([]);
  const [showMap, setShowMap] = useState(true);
  const [areaSelect, setAreaSelect] = useState(true);
  const [infoOverlayFeature, setInfoOverlayFeature] = useState(null);
  const [infoOverlayClickPosition, setInfoOverlayClickPosition] = useState([0,0]);
  const [isLoadingGeometry, setIsLoadingGeometry] = useState(false);
  const [isLoadingSeaBoundaries, setIsLoadingSeaBoundaries] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const vectorStyle = useMemo(() => {
    return GetContourStyle();
  }, []);

  const seaBoundaryStyle = useMemo(() => {
    let cachedStyle = null;
    return () => {
      if(cachedStyle == null) {
        cachedStyle = new Style({
          stroke: new Stroke({
            color: "#839af7",
            width: 1,
            lineDash: [8, 4],
          }),
        });
      }

      return cachedStyle;
    };
  }, []);

  const view = useMemo(
    () =>
      new olView({
        center: fromLonLat([-3.99703499155975, 50.6297266668772]),
        zoom: 10,
      }),
    []
  );

  const handlePointerClick = (e) => {
    const map = e.map;
    const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 10 });
    const lineFeatures = features.filter(
      (feature) => feature.getGeometry() instanceof LineString && feature.get("elevation")
    );

    if (lineFeatures.length > 0) {
      setInfoOverlayFeature(lineFeatures[0]);
      setInfoOverlayClickPosition(e.coordinate);
    } else {
      setInfoOverlayFeature(null);
    }
  };

  const handleOnDrawAreaEnd = (e) => {
    const originalCoordinates = e.feature.getGeometry().getCoordinates();

    const transformedCoordinates = originalCoordinates[0].map((coord) => {
      return toLonLat(coord);
    });

    setContourFeatures([]);

    setIsLoadingGeometry(true);
    setIsLoadingSeaBoundaries(true);
    setAreaSelect(false);

    getByGeometry(transformedCoordinates).then((response) => {    
      const features = response.data.map((contour) => {
        const lineString = new LineString(
          contour.geometry.coordinates.map((coordinate) => {
            return fromLonLat(coordinate);
          })
        );
        let feature = new Feature(lineString);
        feature.set("elevation", contour.elevation);
        feature.set(
          "maxElevation",
          Math.max(...response.data.map((c) => c.elevation))
        );
        feature.set(
          "minElevation",
          Math.min(...response.data.map((c) => c.elevation))
        );
        return feature;
      });

      setContourFeatures(features);
      setIsLoadingGeometry(false);
    }).catch((error) => {
      alert(error.message);
      setIsLoadingGeometry(false);
      setContourFeatures([]);
    });

    getSeaBoundariesByGeometry(transformedCoordinates).then((response) => {
      const features = response.data.map((contour) => {
        const lineString = new LineString(
          contour.geometry.coordinates.map((coordinate) => {
            return fromLonLat(coordinate);
          })
        );
        let feature = new Feature(lineString);
        feature.set("elevation", contour.elevation);
        feature.set(
          "maxElevation",
          Math.max(...response.data.map((c) => c.elevation))
        );
        feature.set(
          "minElevation",
          Math.min(...response.data.map((c) => c.elevation))
        );
        return feature;
      });

      setSeaBoundaryFeatures(features);
      setIsLoadingSeaBoundaries(false);
    });
  };

  useEffect(() => {
    if(isLoadingGeometry) {
      setLoadingMessage("Loading Contour Data...");
    } else if(isLoadingSeaBoundaries) {
      setLoadingMessage("Loading Sea Boundary Data...");
    } else {
      setLoadingMessage("");
    }
  }, [isLoadingGeometry, isLoadingSeaBoundaries]);

  return (
    <>
     <div
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "4px",
            borderRadius: 5,
            zIndex: 2,
            position: "absolute",
            top: 100,
            left: 20,
          }}
        >
          <FormCheck
            type="checkbox"
            label="Show Map"
            checked={showMap}
            onChange={(e) => {
              setShowMap(e.target.checked);
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "4px",
            borderRadius: 5,
            zIndex: 2,
            position: "absolute",
            top: 150,
            left: 20,
          }}
        >
          <FormCheck
            type="checkbox"
            label="Draw Area"
            checked={areaSelect}
            onChange={(e) => {
              setAreaSelect(e.target.checked);
            }}
          />
        </div>
      <div className="contourViewMapContainer">  
        {isLoadingGeometry && <LoadingSpinner Message={loadingMessage} />}
        <Map view={view} onClick={handlePointerClick}>
          <Layers>
            {showMap && <TileLayer source={osm()} zIndex={0} />}
            <VectorLayer style={seaBoundaryStyle} zIndex={3}>
              <MyVectorSource features={seaBoundaryFeatures} display={true} />
            </VectorLayer>
            <VectorLayer style={vectorStyle} zIndex={2}>
              <MyVectorSource features={contourFeatures} display={true} />
            </VectorLayer>
          </Layers>
          <Interactions>
            <DrawInteraction OnDrawEnd={handleOnDrawAreaEnd} Enabled={areaSelect} />
          </Interactions>
          <Overlays>
            <ContourHeightOverlay
              feature={infoOverlayFeature}
              clickPos={infoOverlayClickPosition}
            />
          </Overlays>
        </Map>
      </div>
    </>
  );
};
