import Draw from 'ol/interaction/Draw.js';
import { useContext, useEffect, useRef } from "react";
import MapContext from "../Map/MapContext";

export const DrawInteraction = ({VectorSource, OnDrawEnd, Enabled}) => {
    const { map } = useContext(MapContext);
    
    const drawInteraction = useRef(null);

    useEffect(() => {
        let draw = new Draw({
            source: VectorSource,
            type: 'Polygon'
        });
        
        draw.on('drawend', OnDrawEnd);

        drawInteraction.current = draw;
    }, []);

    useEffect(() => {
        if (!map) return;
        if (Enabled) {
            map.addInteraction(drawInteraction.current);
        } else {
            map.removeInteraction(drawInteraction.current);
        }
    }, [Enabled, map]);

    return null;
};